.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  
    margin: 0 auto;
    padding: 1.5rem;
  }
  .title {
    font-family: 'Assistant', sans-serif;
    font-size: 2rem;
    color: #281916;
    margin: 2% auto;
    text-align: center;
    font-weight: bold;
}
  .videoWrapper {
    flex: 0 1 calc(33.333% - 1.5rem);
    min-width: 250px;
    max-width: 300px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .videoWrapper:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .video {
    width: 100%;
    height: auto;
    display: block;
  }
  
  @media (max-width: 800px) {
    .videoWrapper {
      flex: 0 1 calc(50% - 1.5rem);
    }
    .title { font-size: 1.8rem; }
  }
  
  @media (max-width: 550px) {
    .videoWrapper {
      flex: 1 1 100%;
      max-width: 80%;
    }
    .title { font-size: 1.6rem; }
  }