.container {
    direction: rtl;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .mainTitle {
    font-size: 2rem;
    color: #281916;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Assistant', sans-serif;
    font-weight: bold;
  }
  
  .part {
    margin-bottom: 30px;
  }
  
  .partTitle {
    font-size: 1.5rem;
    color: #281916;
    margin-bottom: 15px;
    font-weight: bold;
    font-family: 'Assistant', sans-serif;
  }
  
  .itemList {
    list-style-type: none;
    padding: 0;
  }
  
  .itemList li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-family: 'AssistantR', sans-serif;
    font-size: 1.2rem;
    color: #281916;
  }
  
  .icon {
    margin-left: 10px;
    color: #432918;
  }
  
  .additionalSection {
    margin-top: 40px;
    text-align: center;
  }
  
  .additionalTitle {
    font-size: 1.5rem;
    color: #281916;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'Assistant', sans-serif;
  }
  
  .additionalImage {
    max-width: 35%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  @media (max-width: 600px) {
    .mainTitle {
      font-size: 1.5rem;
    }
  
    .partTitle, .additionalTitle {
      font-size: 1.3rem;
    }
  
    .itemList li {
      font-size: 1rem;
    }
  
    .additionalImage {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      max-width: 80%;
    }
  }