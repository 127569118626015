.center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .button {
    font-family: 'AssistantR';
    font-size:1.1rem;
    width:300px;
    color: rgba(0, 0, 0, 0.8);
    background-color: rgba(255, 255, 255, 0.2);
    padding: 15px 30px;
    border: none;
    border-radius: 10px;
    direction: rtl;
    cursor: pointer;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 
      0 4px 6px rgba(0, 0, 0, 0.1), 
      inset 0 1px 0 rgba(255, 255, 255, 0.3),
      inset 0 -2px 5px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
  }
  
  .button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    transition: all 0.6s;
  }
  
  .button:hover {
    background-color: rgba(255, 255, 255, 0.25);
    color: rgba(0, 0, 0, 0.9);
    transform: translateY(-2px);
    box-shadow: 
      0 6px 8px rgba(0, 0, 0, 0.15), 
      inset 0 1px 0 rgba(255, 255, 255, 0.4),
      inset 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .button:hover::before {
    left: 100%;
  }
  
  .button:active {
    transform: translateY(1px);
    box-shadow: 
      0 2px 4px rgba(0, 0, 0, 0.1), 
      inset 0 1px 0 rgba(255, 255, 255, 0.2),
      inset 0 -1px 3px rgba(0, 0, 0, 0.05);
  }