.bubble {
  background: linear-gradient(135deg, #eaddd9 0%, #f5e8e3 100%);
  border-radius:15px; /* פינות מעוגלות */
  padding: 25px;
  width: 280px; /* רוחב קבוע */
  margin: 15px auto;
  box-shadow: 0 4px 10px rgba(68, 68, 68, 0.15);
  transition: all 0.3s ease;
  direction: rtl;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bubble::before {
  content: '';
  position: absolute;
  bottom: -20px;
  right: -15px;
  width: 50px;
  height: 50px;
  background: #c3a49a;
  border-radius: 50%;
  opacity: 0.5;
}

.bubble:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(68, 68, 68, 0.2);
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
}

.title {
  font-size: 1.4rem;
  font-family: "Assistant";
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: #5e1f00;
  direction: rtl;
  margin-bottom: 10px;
}

.description {
  font-size: 0.95rem;
  color: #000000;
  line-height: 1.4;
  direction: rtl;
}


@media only screen and (max-width: 350px) {
  .bubble {
      width: 80%;
      padding: 20px;
      margin: 10px auto;
  }
  .lessonNumber { font-size: 1.1rem; }
  .title { font-size: 1.25rem; }
  .description { font-size: 0.9rem; }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
  .bubble {
      width: 80%;
      padding: 22px;
      margin: 12px auto;
  }
  .lessonNumber { font-size: 1.1rem; }
  .title { font-size: 1.3rem; }
  .description { font-size: 0.9rem; }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
  .bubble {
      width: 75%;
      padding: 23px;
      margin: 13px auto;
  }
  .lessonNumber { font-size: 1.15rem; }
  .title { font-size: 1.35rem; }
  .description { font-size: 0.92rem; }
}

