.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title{
  font-size:3rem;
  font-family: "Assistant";
  text-align: center;
  margin:1% auto;
  text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
  direction:rtl
}

.form {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 10px;
  direction: rtl;
  border-radius: 8px;
}

.input {
  padding: 12px;
  margin: 10px 0;
  border: none;
  direction: rtl;
  border-radius: 4px;
  background: linear-gradient(135deg, 
    #FFF8E7,  /* שמנת בהיר */
    #F5E6D3,  /* nude בהיר */
    #EAD7C3,  /* nude בינוני */
    #F8EDE3,  /* שמנת חם */
    #F0E4D7   /* nude עדין */
  );
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
  color: #333;
  font-family: "AssistantR";
  outline: none;
  transition: box-shadow 0.3s ease;
}

.input::placeholder {
  color: #B3A598;  /* גוון nude כהה יותר לפלייסהולדר */
}

.input:focus {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.button {
  padding: 12px;
  margin: 20px 0 0;
  border: none;
  border-radius: 4px;
  background: linear-gradient(135deg, 
    #FFF8E7,  /* שמנת בהיר */
    #F5E6D3,  /* nude בהיר */
    #EAD7C3,  /* nude בינוני */
    #F8EDE3,  /* שמנת חם */
    #F0E4D7   /* nude עדין */
  );
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
  color: #333;
  font-family: "Assistant";
  cursor: pointer;
  transition: all ease 0.5s;
}

.button:hover {
  background: linear-gradient(135deg, 
    #F0E4D7,  /* nude עדין */
    #F8EDE3,  /* שמנת חם */
    #EAD7C3,  /* nude בינוני */
    #F5E6D3,  /* nude בהיר */
    #FFF8E7   /* שמנת בהיר */
  );
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 350px){
  .formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .title{
    font-size:2rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
    direction:rtl
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 300px;
    direction: rtl;
    border-radius: 8px;
  }
  
  .input {
    padding: 12px;
    margin: 10px 0;
    border: none;
    direction: rtl;
    border-radius: 4px;
    background: linear-gradient(135deg, 
      #FFF8E7, #F5E6D3, #EAD7C3, #F8EDE3, #F0E4D7
    );
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
    color: #333;
    font-family: "AssistantR";
    outline: none;
    transition: box-shadow 0.3s ease;
  }
  
  .input::placeholder {
    color: #B3A598;
  }
  
  .input:focus {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
  
  .button {
    padding: 12px;
    margin: 10px 0 0;
    border: none;
    border-radius: 4px;
    background: linear-gradient(135deg, 
      #FFF8E7, #F5E6D3, #EAD7C3, #F8EDE3, #F0E4D7
    );
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
    color: #333;
    font-family: "Assistant";
    cursor: pointer;
    transition: all ease 0.5s;
  }
  
  .button:hover {
    background: linear-gradient(135deg, 
      #F0E4D7, #F8EDE3, #EAD7C3, #F5E6D3, #FFF8E7
    );
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 350px) and (max-width: 450px){
  .formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .title{
    font-size:2rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    width:90%;
    text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
    direction:rtl
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 300px;
    direction: rtl;
    border-radius: 8px;
  }
  
  .input {
    padding: 12px;
    margin: 10px 0;
    border: none;
    direction: rtl;
    border-radius: 4px;
    background: linear-gradient(135deg, 
      #FFF8E7, #F5E6D3, #EAD7C3, #F8EDE3, #F0E4D7
    );
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
    color: #333;
    font-family: "AssistantR";
    outline: none;
    transition: box-shadow 0.3s ease;
  }
  
  .input::placeholder {
    color: #B3A598;
  }
  
  .input:focus {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
  
  .button {
    padding: 12px;
    margin: 10px 0 0;
    border: none;
    border-radius: 4px;
    background: linear-gradient(135deg, 
      #FFF8E7, #F5E6D3, #EAD7C3, #F8EDE3, #F0E4D7
    );
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
    color: #333;
    font-family: "Assistant";
    cursor: pointer;
    transition: all ease 0.5s;
  }
  
  .button:hover {
    background: linear-gradient(135deg, 
      #F0E4D7, #F8EDE3, #EAD7C3, #F5E6D3, #FFF8E7
    );
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 450px) and (max-width: 550px){
  .formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .title{
    font-size:2.2rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    width:90%;
    text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
    direction:rtl
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 300px;
    direction: rtl;
    border-radius: 8px;
  }
  
  .input {
    padding: 12px;
    margin: 10px 0;
    border: none;
    direction: rtl;
    border-radius: 4px;
    background: linear-gradient(135deg, 
      #FFF8E7, #F5E6D3, #EAD7C3, #F8EDE3, #F0E4D7
    );
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
    color: #333;
    font-family: "AssistantR";
    outline: none;
    transition: box-shadow 0.3s ease;
  }
  
  .input::placeholder {
    color: #B3A598;
  }
  
  .input:focus {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
  
  .button {
    padding: 12px;
    margin: 10px 0 0;
    border: none;
    border-radius: 4px;
    background: linear-gradient(135deg, 
      #FFF8E7, #F5E6D3, #EAD7C3, #F8EDE3, #F0E4D7
    );
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
    color: #333;
    font-family: "Assistant";
    cursor: pointer;
    transition: all ease 0.5s;
  }
  
  .button:hover {
    background: linear-gradient(135deg, 
      #F0E4D7, #F8EDE3, #EAD7C3, #F5E6D3, #FFF8E7
    );
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 550px) and (max-width: 750px){
  .formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .title{
    font-size:2.2rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    width:70%;
    text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
    direction:rtl
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 300px;
    direction: rtl;
    border-radius: 8px;
  }
  
  .input {
    padding: 12px;
    margin: 10px 0;
    border: none;
    direction: rtl;
    border-radius: 4px;
    background: linear-gradient(135deg, 
      #FFF8E7, #F5E6D3, #EAD7C3, #F8EDE3, #F0E4D7
    );
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
    color: #333;
    font-family: "AssistantR";
    outline: none;
    transition: box-shadow 0.3s ease;
  }
  
  .input::placeholder {
    color: #B3A598;
  }
  
  .input:focus {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
  
  .button {
    padding: 12px;
    margin: 10px 0 0;
    border: none;
    border-radius: 4px;
    background: linear-gradient(135deg, 
      #FFF8E7, #F5E6D3, #EAD7C3, #F8EDE3, #F0E4D7
    );
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
    color: #333;
    font-family: "Assistant";
    cursor: pointer;
    transition: all ease 0.5s;
  }
  
  .button:hover {
    background: linear-gradient(135deg, 
      #F0E4D7, #F8EDE3, #EAD7C3, #F5E6D3, #FFF8E7
    );
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
}



@media only screen and (min-width: 750px) and (max-width: 950px){
  .formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .title{
    font-size:2.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    width:90%;
    text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
    direction:rtl
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 300px;
    direction: rtl;
    border-radius: 8px;
  }
  
  .input {
    padding: 12px;
    margin: 10px 0;
    border: none;
    direction: rtl;
    border-radius: 4px;

    font-size: 1.1rem;
    color: #333;
    font-family: "AssistantR";
    outline: none;
    transition: box-shadow 0.3s ease;
  }
  
  .input::placeholder {
    color: #a0988c;
  }
  
  .input:focus {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
  
  .button {
    padding: 12px;
    margin: 10px 0 0;
    border: none;
    border-radius: 4px;
    background: rgba(245, 240, 235, 0.7);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
    color: #333;
    font-family: "Assistant";
    cursor: pointer;
    transition: all ease 0.5s;
  }
  
  .button:hover {
    background: rgba(235, 225, 215, 0.8);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
}